<template>
  <div class="level">
    <div class="level-item">
        <div class="card" v-if="showForm">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">User Registration</h4>
        </header>
        <div class="card-content">  
            <form action="" @submit.prevent="handleSubmit">
                
                <div class="section">

                <b-field label="Email" label-for="email">
                    <b-input
                    name="email" 
                    id="email" 
                    type="email"
                    placeholder="Email Address"
                    icon-pack="fas" 
                    icon="envelope" 
                    v-model=user.email
                    disabled=true 
                    validation-message="Please enter a vaild email address">
                    </b-input>
                </b-field>

                <div class="has-text-weight-bold">
                    <b-tooltip class="is-default" position="is-right" :always="isToolTipActive" animated multilined>
                        Password: <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                        <template v-slot:content>
                            <div class="panel">
                                <div class="is-underlined has-text-danger has-background-warning">PASSWORD REQUIREMENTS</div>

                                <ul>
                                    <li>
                                        Minimum Length 8
                                    </li>
                                    <li>
                                        At least 1 Number
                                    </li>
                                    <li>
                                        At least 1 Uppercase Letter
                                    </li>
                                    <li>
                                        At least 1 Lowercase Letter
                                    </li>                        
                                </ul> 
                            </div>
                        </template>
                    </b-tooltip>
                    <b-field label="" label-for="password">
                        <b-input
                            ref="PasswordInput"
                            name="password" 
                            id="password" 
                            type="password"
                            placeholder="Enter password"
                            icon-pack="fas" 
                            icon="lock" 
                            v-model="user.password" 
                            minlength=8
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            validation-message="Password does not meet requirements.">
                        </b-input>
                    </b-field>                                         
                </div>                      

                <b-field label="Repeat Password" label-for="repeatpassword">
                    <b-input
                    name="repeatpassword" 
                    id="repeatpassword" 
                    type="password"
                    placeholder="Repeat the password you entered above"
                    icon-pack="fas" 
                    icon="lock" 
                    v-model="repeatPassword"
                    minlength=8
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    password-reveal
                    validation-message="Repeat the password does not match with password">
                    </b-input>
                </b-field>
                    
                <b-field label="Full Name" label-for="name">
                    <b-input
                    name="name" 
                    id="name" 
                    type="name"
                    placeholder="Enter your full name"
                    icon-pack="fas" 
                    icon="user" 
                    v-model="user.name"
                    minlength=3
                    aria-required
                    validation-message="Please enter a valid name. Requires at least 3 characters">
                    </b-input>
                </b-field>
                    
                <b-field label="Phone" label-for="phoneNumber">
                    <b-input
                    name="phoneNumber" 
                    id="phoneNumber" 
                    type="tel"
                    placeholder="Phone Number"
                    icon-pack="fas" 
                    icon="mobile" 
                    v-model="user.phoneNumber"
                    minlength=10
                    validation-message="Please enter a valid phone number">
                    </b-input>
                </b-field>

                <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
                <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
                </div>
            </form>
            </div>
        </div>

        <div class="card" v-if="postSuccess">
            <header class="card-header has-background-primary">
                <h4 class="card-header-title is-centered title is-4 has-text-white">User Registration Successful</h4>
            </header>

            <div class="card-content is-gray">
                <div class="block has-background-light">
                    <h2 class="subtitle has-text-centered ">

                        <strong class="is-size-4 has-text-success">Successfully completed registration. <br>Thank you! <br>
                        You are ready to log in and use VEECLi Services.</strong>
                    </h2>                
                </div>

                <div class="block">
                    <strong class="is-size-3"><u>NEXT STEPS</u></strong> (Please read)
                </div>

                <div class="block has-text-centered has-background-white-ter ">
                    <p><strong>ADD YOUR GAS STATIONS</strong></p>
                    <p class="has-text-weight-bold">Log in to <a href="https://app.veecli.com/userlogin">VEECLi Web Application</a> <br> If another user has not already added your stores, then add your stores.</p>
                    <hr>
                    <a class="button is-primary is-large" href="https://app.veecli.com/userlogin">Log in</a><br>
                    <hr>
                    <p class="is-size-6"><i>Add Store feature is available only on our <a href="https://app.veecli.com/userlogin">VEECLi Web Application</a> </i></p>
                    <p><i>If another user of your store already added your store <b>DO NOT ADD IT AGAIN</b>.</i></p><p><i>Request the person who added the store to give you access</i></p>
                </div>

                <div class="block has-text-centered  has-background-white-ter ">
                    <p><strong>DOWNLOAD AND INSTALL <br>VEECLi App from your favorite App Store.</strong>
                    </p>
                    <a href="https://apps.apple.com/app/id1518816103" target="_app"><img src="/images/apple-appstore-59.png"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.veecli.gsmanager" target="_app"><img src="/images/google-playstore-200.png"></a>
                </div>

                <div class="block has-text-centered has-background-white-bis ">
                    <p class="has-text-weight-bold">LOG IN <br>to VEECLi Mobile App.</p>
                    <p>At least 1 of your Gas Stations must be setup using the <a href="https://app.veecli.com/userlogin">VEECLi Web Application</a> and/or another owner/partner gave you access to 1 or more Gas Stations.</p>
                </div>

                <div class="block has-text-centered  has-background-white-ter">
                    <p>Thank you for subscribing to VEECLi Service.  Please do not hesitate to contact us if you have any questions or concerns.</p>
                    <p>.</p>
                    <p>It takes a few steps to get your store up and running. We will send the VEECLi Appliance device to the store in the next few days.  Once received, please install it and send an email/call support to validate and complete on-boarding your store.</p>
                </div>                        

            </div>
        </div>
        <div class="card"  v-if="userActivated">
            <header class="card-header has-background-primary">
                <h4 class="card-header-title is-centered title is-4 has-text-white">Welcome to VEECLi</h4>
            </header> 
            <div class="card-content is-gray">
                <!-- <P class="subtitle has-text-danger"  v-if="userActivated"> -->


                <div class="box">
                    <strong class="is-size-5">Please log in if you have already completed the registration by setting your password.</strong><br>
                    <a class="button is-primary is-large" href="https://app.veecli.com/userlogin">Log in</a><br>
                </div>


                <div class="box">
                    <strong class="is-size-5">Please sign up if you are new to our service.</strong><br>
                    <a class="button is-primary is-large" href="https://app.veecli.com/usersignup">Signup</a><br>
                </div>


                <div class="box has-text-centered  has-background-white-ter ">
                    <p><strong>DOWNLOAD AND INSTALL VEECLi App<br>from your favorite App Store.</strong>
                    </p>
                    <a href="https://apps.apple.com/app/id1518816103" target="_app"><img src="/images/apple-appstore-59.png"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.veecli.gsmanager" target="_app"><img src="/images/google-playstore-200.png"></a>
                </div>

                <div class="box has-text-centered">
                    <strong class="is-size-5">If you already signed up and having difficulty to log in, please contact support@veecli.com.</strong><br>
                    <!-- <strong class="is-size-5 has-text-danger">Invalid or expired key.  Please </strong> -->
                </div>                    

                <!-- </p> -->
                <h2 class="subtitle"  v-if="errors">
                    <ul>
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>
                </h2>            
            </div>
        </div>
  </div>
  </div>
</template>

<script>

import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import ErrorMessage from '../../components/app/ErrorMessage'
import UserStore from "../../store/UserStore"

export default {
    
    data() {
        return {
            user: [],
            errors: [],
            showForm: false,
            repeatPassword: '',
            postSuccess: false,
            heroColor: 'is-primary',
            messagetype: 'is-warning',
            isToolTipActive: true,
            
        }
    },

    components: {
        ErrorMessage
    },

    methods: {

        handleSubmit() {

            if ( this.formComplete ) {

                this.errors = []

                // var htmlEmailNotifyEmail = ["aby.thomas@veecli.com"]
                // var htmlEmailMessage = "User activated: <br>" 
                // var htmlEmailSubject = "User " + this.user.email + " activated."

                var crypto = require('crypto')
                const md5password = crypto.createHash('md5').update(this.user.password).digest("hex")

                var url = process.env.VUE_APP_API_ROOT_URL + "/users/register"
                
                axios.put(url, {
                        id: this.user.id,
                        name: this.user.name,
                        phoneNumber: this.user.phoneNumber,
                        email: this.user.email,
                        newPassword: md5password,
                        key: this.$route.query.key
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + this.user.newToken,
                            'correlation-id': this.$uuid.v1()
                        },  
                    })
                    .then( (response => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.showForm = false
                        this.postSuccess = true

                        // htmlEmailMessage += "Email: <b>" + response.data.email.toLowerCase() + "</b><br>" 
                        // htmlEmailMessage += "Name: <b>" + (response.data.activated ? response.data.name : "")+ "</b><br>" 
                        // htmlEmailMessage += "Phone: <b>" + (response.data.activated ? response.data.phoneNumber : "") + "</b><br>" 
                        // htmlEmailMessage += "Store: <b>" + this.activeStoreName() + "</b><br>"
                        // htmlEmailMessage += "User: <b>" + this.user.name + "</b><br>"
                        // htmlEmailMessage += "Role : <b>" + (response.data.storeUsers.length > 0 ? response.data.storeUsers[0].role : "N/A") + "</b><br>"
                        // htmlEmailMessage += "Status: <b>" + (response.data.activated ? "Active" : "Not Active")  + "</b>"
                        
                        // console.log(htmlEmailMessage)

                        // this.sendEmail(
                        //     htmlEmailNotifyEmail,
                        //     htmlEmailSubject,
                        //     htmlEmailMessage)
                    }))
                .catch( (err) => {
                    this.errors.push(err.message)
                })
            }

        },

        getUserToActivate() {
                   
            if ( this.$route.query.key ) {

                axios.get(process.env.VUE_APP_API_ROOT_URL + '/users/register?key=' + this.$route.query.key ,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                
                    })
                .then( (response => {

                    this.user = response.data

                    if (this.user.activated) {
                        this.heroColor = 'is-danger'
                        this.showForm = false
                    } else {
                        this.showForm = true
                    }
                    
                }))
                .catch( (err) => {
                    this.showForm = false
                    this.heroColor = 'is-danger'
                    this.errors.push(err.message)
                    this.$buefy.notification.open({
                            message: err.response.data + '. <br>Please contact support@veecli.com!',
                            type: 'is-danger',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })
                    
                })
                   
            }       
        },

        isEmailValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },

    },

    computed: {

        formComplete() {
            if (! this.isEmailValid(this.user.email)) {
                return false
            }
            
            if (! this.user.name ) {
                return false
            }

            if (! this.user.password) {
                return false
            }

            if (! this.repeatPasswordMatched ) {
                return false
            }

            return true
        },

        userActivated() {
            if ( this.user.activated ) {
                return true
            } else {
                return false
            }
        },

        repeatPasswordMatched() {
            if (this.user.password == this.repeatPassword) {
                return true
            }
            return false
        }
    },

    mounted() {

        this.getUserToActivate()

        // this.$nextTick(() => {
        //     this.$refs.PasswordInput.focus()
        // })
        
        // setTimeout(this.isToolTipActive = false, 2000)
        setTimeout(function() { 
            this.isToolTipActive = false
            this.$refs.PasswordInput.focus()
        }.bind(this), 4000)
        
    }

}
</script>


<style scoped>

.container {
    padding-top: 100px;
}

.level {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
}
</style>